import React, { useState, useEffect, useRef } from 'react';
import { Route, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { DataStoreProvider } from './dataStoreContext';
//import {getToken, onMessageListener} from './firebase.js';

import Home from './home';
import Sidebar from './sidebar';
import FaqView from 'js/views/faqView.js';
import SetPasswordView from 'js/views/setPasswordView.js';
import SettingsView from 'js/views/settingsView.js';
import AboutView from 'js/views/aboutView.js';
import HistoryView from 'js/views/historyView.js';
import SupportView from 'js/views/supportView.js';
import MapView from 'js/views/mapView.js';
import TopOverlayView from 'js/views/topOverlayView.js';
import BottomOverlayView from 'js/views/bottomOverlayView.js';
import OutletInfoView from 'js/views/outletInfoView.js';
import CardPaymentView from 'js/views/cardPaymentView.js';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import {InfoModal, SupportModal, HelpTextModal, InitScreen} from 'js/components/modals.js';
import HelperService from "services/helpers.js";
import moment from 'moment';

import 'moment-duration-format';

import './styles/app.scss';
import './styles/sidebar.scss';
import Api from './services/api';
import queryString from 'query-string';
import { useOnlineStatus } from './services/hooks';
import NetworkErrorComponent from './js/components/networkError';


/*
The App component is responsible for routing and layout of the main view flow in the app. It's a bit complex since it is using 
React Router children prop for rendering the routes (https://reactrouter.com/web/api/Route/children-func). 
Reason for this is that we often want to render two routes (views) at the same time since a view often slides in over another view
when it is invoked. Thus, during the transition period (400ms) both views must be visible at same time.

The home view, home.js (landing page) is the bottom view and over this one other views are transistioned depending on user choice 
(settings, history, about etc.) Only one level of transition (e.g. home -> settings -> home) is currently supported. Two levels 
(home -> settings -> xxx -> settings -> home) is not supported.

*/
  

const routes = [
    {
        path: "/",
        direction: 'left',
        exact: false,
        sidebar: () => <div>home!</div>,
        main: (props) => {
          return (
              <Home toggleMenu={props.toggleMenu} firstRun={props.firstRun} hideInitScreenCB={ () => {props.handleHideInitScreen() } } />
          );
        }
      },
    {
        path: "*/faq",
        direction: 'right',
        sidebar: () => <div>Not used</div>,
        main: (props) => <FaqView  toggleMenu={props.toggleMenu}/>
    },
    {
        path: "*/history",
        direction: 'right',
        sidebar: () => <div>Not used</div>,
        main: (props) => <HistoryView  className="xxx" toggleMenu={props.toggleMenu}/>
    },
    {
        path: "*/map",
        direction: 'right',
        sidebar: () => <div>Not used</div>,
        main: (props) => <MapView  className="xxx" toggleMenu={props.toggleMenu}/>
    },
    {
        path: "*/about",
        direction: 'right',
        sidebar: () => <div>Not used</div>,
        main: (props) => <AboutView  toggleMenu={props.toggleMenu}/>
    },
    {
        path: "*/support",
        direction: 'right',
        sidebar: () => <div>Not used</div>,
        main: (props) => <SupportView  toggleMenu={props.toggleMenu}/>
    },
    {
        path: "*/settings",
        direction: 'right',
        sidebar: () => <div>Not used</div>,
        main: (props) => {
            return (
                <SettingsView  toggleMenu={props.toggleMenu}/>
            );
        }
    },
    {
        path: "*/cardpayment",
        direction: 'right',
        sidebar: () => <div>Not used</div>,
        main: (props) => {
            return (
                <CardPaymentView toggleMenu={props.toggleMenu}/>
            );
        }
    },
    {
        //path: "*/([0-9]{3}-[0-9]{3}-[0-9]{3})",
        path: "*/provider",
        direction: 'right',
        sidebar: () => <div>Not used</div>,
        main: (props) => {
            return (
                <OutletInfoView  toggleMenu={props.toggleMenu}/>
            );
        }
      },
          {
        //path: "*/([0-9]{3}-[0-9]{3}-[0-9]{3})",
        path: "*/setpw",
        direction: 'right',
        sidebar: () => <div>Not used</div>,
        main: (props) => {
            return (
                <SetPasswordView  toggleMenu={props.toggleMenu}/>
            );
        }
      }

    
  ];

  //export default function App() {
    const App = () => {

    const OVERLAY_MODE = {
        LOGIN: 'login',
        REGISTER: 'register',
        FORGOTPW: 'forgotpw',
        PROFILE: 'profile'
      };


    let history = useHistory();  
    const { i18n } = useTranslation('views',);

    const [overlayMode, setOverlayMode]  = useState(OVERLAY_MODE.LOGIN);
    const [showOverlay, setShowOverlay]  = useState(false);

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [scannedOutlet, setScannedOutlet] = useState(null);
    
    const [infoModal, setInfoModal]  = useState([]);
    const [supportModal, setSupportModal]  = useState([]);
    const [helpTextModal, setHelpTextModal]  = useState([]);
    const [firstRun, setFirstRun] = useState(true);
    const [historicalSession, setHistoricalSession] = useState(null);
    
    let location = useLocation();

    const [pageDirection, setPageDirection] = useState('right');
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const [showInitScreen, setShowInitScreen] = useState(true); // TODO: true in production!!
    
    const [showInfoModal, setShowInfoModal]  = useState(false);
    const [showSupportModal, setShowSupportModal]  = useState(false);
    const [showHelpTextModal, setShowHelpTextModal]  = useState(false);
    const [supportModalProvider, setSupportModalProvider]  = useState(null);
    const [modalText, setModalText]  = useState('');
    const [modalOkCallback, setModalOkCallback]  = useState(() => () => {setShowInfoModal(false)});
    const [supportModalOkCallback, setSupportModalOkCallback]  = useState(() => () => {setShowSupportModal(false)});
    const [modalCancelCallback, setModalCancelCallback]  = useState(null);
    const [currentTheme, setCurrentTheme]  = useState(null); //'light', 'dark'
    const appIsVisible_ref = useRef(true); //app always start as visible
    const onlineStatus = useOnlineStatus();
    const [showNetworkError, setShowNetworkError] = useState(false);
    const { t } = useTranslation("components")
    
    
     
    
    const loadTheme = (theme) => {
        if (currentTheme === theme) return;
        let color = '';
        if (theme === 'light') {
            color = '#ffffff';
        }
        else {
            color = '#000000';
        }
        document.querySelector(":root").style.setProperty('--overlay-color', color);
        setCurrentTheme(theme);
    }

    window.flutterApi.setNotificationToken = (token) => {
        setNotificationToken(token);
    }

    window.flutterApi.setFlutterAppVersion = (version) => {
        if (Api) Api.flutterVersion = version;
        setFlutterAppVersion(version);
    }

    // eslint-disable-next-line no-unused-vars 
    const sendToFlutter = (dataString) => {
        if (window.messageHandler) window.messageHandler.postMessage(dataString);
    }

    /**
     * notification is a string, e.g. 
     * "{notification: {title: Elsession avslutad, 
     * body: Din elsession på uttag 000-000-678 har avslutats, skäl: avslutad av leverantör.}, 
     * data: {SESSION_ID: 809990042}}
     */
    window.flutterApi.showNotification = (title, body, sessionId) => {
            console.log('*****************************************');
            console.log(title);
            console.log(sessionId);
            //let notification = JSON.parse(notificationString);
            //let notification = notificationString;            
            let dialogText = "session finished";
            if (body) dialogText = body;
            else if (title) dialogText = title;
            refreshUsageData();
            setInfoModal([ dialogText ]);
    }

        window.flutterApi.parseQrCode = (qrString) => {
            console.log('*****************************************');
            console.log(qrString);
            let outletId = HelperService.getPathOutletId(qrString);
            if (outletId) setExternalOutletToOpen(outletId);
        }

        // position is a String: "Latitude: 59.3248541, Longitude: 17.992176"
        window.flutterApi.updateCurrentPosition = (position) => {
            console.log('****************M*************************');
            console.log(position);
            let parts = position.split(',');
            if (parts.length === 2) {
                let lat = parseFloat(parts[0].substring(1 + parts[0].indexOf(':')));
                let lng = parseFloat(parts[1].substring(1 + parts[1].indexOf(':')));
                if (!isNaN(lat) && !isNaN(lng)) {
                    console.log('Setting user position from Flutter: ' + lat + ', ' +lng);
                    setUserPosition([lat, lng]);
                }

            }
            //console.log('lat: ' +lat + ', lng: ' + lng);
            //setUserPosition(lat, lng);
            
        }    

    
    /** User settings have the following content/structure: 
     *  {
     *      theme: 'light' | 'dark' //whether GUI runs in dark or light mode (not implemented)
     *      primaryUnit: 'time' | 'kwh' //purchase method for last purchase
     *      devOptions: {
     *          usePlayground: true | false, //use playground project
     *          verboseInfo: true | false, //show developer details in various views
     *          quickCloseLoadingScreen: true | false, //imediately close the initial loading screen
     *          useDummyPayment: true | false, //use test account for Stripe
     *      }
     *  }
     * 
     * 
    */

     

    const loadSettings = () => {
        let us = null;
        try {
            us = window.localStorage.getItem('userSettings');
            if (us) {
                us = JSON.parse(us);
                if ( ! us.devOptions ) us.devOptions = { usePlayground: false, verboseInfo: false, quickCloseLoadingScreen: false, useDummyPayment: false};
            }
            else {
                us = {devOptions: { usePlayground: false, verboseInfo: false, quickCloseLoadingScreen: false, useDummyPayment: false}, theme: 'light', primaryUnit: 'time'};
            }
        } catch (error) {
            console.log(error);
            us = {devOptions: { usePlayground: false, verboseInfo: false, quickCloseLoadingScreen: false, useDummyPayment: false}, theme: 'light', primaryUnit: 'time'};
        }
        loadTheme( (us.theme)?us.theme:'light');
        return us;
    }

    /* The user's local settings on this device that are stored in persistent storage and applied each time the app is started */
    const [userSettings, setUserSettings] = useState(loadSettings);
    const [userMe, setUserMe] = useState(null);
    const [userPosition, setUserPosition] = useState(null);
    const [paymentCards, setPaymentCards] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [paymentReceipts, setPaymentReceipts] = useState(null);
    const [memberships, setMemberships] = useState(null);
    const [config, setConfig] = useState(null);
    const [activeSessions, setActiveSessions] = useState(null);
    const [completedSessions, setCompletedSessions] = useState(null);
    const [notificationToken, setNotificationToken] = useState(null);
    const [flutterAppVersion, setFlutterAppVersion] = useState(null);
    const [nearbyLocations, setNearbyLocations] = useState(null);
    const [isFlutterApp, setIsFlutterApp] = useState(null);
    const [sendFlutterNotification, setSendFlutterNotification] = useState(null);
    const [externalOutletToOpen, setExternalOutletToOpen] = useState(null);
    const [appInForeground, setAppInForeground] = useState(true);

    const data = {  scannedOutlet, setScannedOutlet, userSettings, setUserSettings, infoModal, setInfoModal, supportModal, setSupportModal, helpTextModal, setHelpTextModal, 
                    historicalSession, setHistoricalSession, paymentCards, setPaymentCards, transactions, setTransactions, paymentReceipts, setPaymentReceipts, memberships, setMemberships, userMe, setUserMe, 
                    config, setConfig, userPosition, setUserPosition, 
                    activeSessions, setActiveSessions, completedSessions, setCompletedSessions, notificationToken, setNotificationToken, 
                    nearbyLocations, setNearbyLocations, isFlutterApp, setIsFlutterApp, sendFlutterNotification, setSendFlutterNotification, 
                    flutterAppVersion, setFlutterAppVersion, externalOutletToOpen, setExternalOutletToOpen, appInForeground, setAppInForeground };



    useEffect(() => {
        const newPath = location.pathname;
        if (newPath !== currentPath) {
            var direction = 'right';
           if (newPath.length > 1) direction = 'left';
            setPageDirection(direction);
            setCurrentPath(newPath);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [location.pathname]);

    /* Called when a component wants to show the info modal via the context*/ 
    useEffect(() => {
        if (infoModal.length > 0) {
            setModalText(infoModal[0]);
            if (infoModal.length > 1) setModalOkCallback(infoModal[1]);
            else setModalOkCallback(null);
            if (infoModal.length > 2) setModalCancelCallback(infoModal[2]);
            else setModalCancelCallback(null);
            setShowInfoModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [infoModal]); 

    /* Called when a component wants to show the support modal via the context*/ 
    useEffect(() => {
        if (supportModal.length > 0) {
            setModalText(supportModal[0]);
            if (supportModal.length > 1) setSupportModalProvider(supportModal[1]);
            else setSupportModalProvider(null);
            if (supportModal.length > 2) setSupportModalOkCallback(supportModal[2]);
            else setSupportModalOkCallback(null);
            setShowSupportModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [supportModal]); 

    /* Called when a component wants to show the help text modal via the context */ 
    useEffect(() => {
        if (helpTextModal.length > 0) {
            setModalText(helpTextModal[0]);
            setShowHelpTextModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [helpTextModal]); 

    // run each time a user logs out/logs in
    useEffect(() => {   
        console.log('USER ME CHANGED!! ');
        //console.log(userMe);

        async function inEffectLoadUnregisteredUserData() { loadUnregisteredUserData() }
        async function inEffectLoadRegisteredUserData() { await loadRegisteredUserData() }
        //async function inEffectAutoLogin(storedToken) { await autoLogin(storedToken) }

        if (!userMe && firstRun) {
            let storedToken = localStorage.getItem('accessToken');
            // user reloaded app with stored credentials
            if (storedToken) {
                // This code is moved to home.js since it must be in same flow as loding outlet in case app is opened with an outlet-id in the url
                //Keep commnted out for now until everything is properly tested
                //inEffectAutoLogin(storedToken);
            }
            else {
                console.log('no userMe, no token - starting unreg mode');
                //load ongoing sessions and locally stored paymentReceipts (for history)
                inEffectLoadUnregisteredUserData();
            }

        }
        else if (!userMe && !firstRun) {
            console.log('Log out');
            localStorage.removeItem('accessToken');
            Api.accessToken = null;
            setScannedOutlet(null);
            history.push("/");
            inEffectLoadUnregisteredUserData();
            
        }
        else {
            console.log('we are logged in: '+userMe.userName);
            setScannedOutlet(null);
            inEffectLoadRegisteredUserData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [userMe]);

    // inital config code after a reload of the app
    useEffect(() => {
        if (!firstRun) return;

        // if the app is hidden we must refresh the usage data when it is getting visible again
        window.document.addEventListener('visibilitychange', function(e) {
            if (window.document.visibilityState === 'visible' && !appIsVisible_ref.current) {
                appIsVisible_ref.current = true;
                setAppInForeground(true);
                refreshUsageData();
            } 
            else {
                appIsVisible_ref.current = false;
                setAppInForeground(false);
            }
          });

        loadConfig();
        setFirstRun(false);
        if (HelperService.getOS() === 'iOS') {
            document.body.classList.add("disable-hover-effects");
            document.body.classList.add("ios-platform");
        }
        else if (HelperService.getOS() === 'Android') {
            document.body.classList.add("disable-hover-effects");
        }
        else {
            document.body.classList.add("enable-hover-effects");
        }
        
        const queryParams = queryString.parse(location.search);
        if (queryParams.isFlutter === 'true') {
            console.log('This is Flutter App');
            setIsFlutterApp(true);
            setSendFlutterNotification((m) => (m) => {sendToFlutter(m)});
        }
        else {
            setIsFlutterApp(false);
        }

        // If this app is embedded in a native (flutter) app we can not use 
        // web push notifications
        if (queryParams.isFlutter !== 'true' && 'PushManager' in window) {
                import('./firebase.js')
                .then((firebase) => {
                    /* Set web push token */
                    firebase.getToken(setNotificationToken);
                    
                    /* Web push message, called when web app is running in browser (i.e. no app) */
                    firebase.onMessageListener().then(payload => {
                        let dialogText = "session finished";
                        console.log(payload);
                        if (payload.notification && payload.notification.body) dialogText = payload.notification.body;
                        else if (payload.notification && payload.notification.title) dialogText = payload.notification.title;
                        refreshUsageData();
                        setInfoModal([ dialogText ]);
                    }).catch(err => console.log('failed: ', err));
                });    
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [firstRun]);

    

    useEffect(() => {   
        try {
            if (userSettings) {
                window.localStorage.setItem('userSettings', JSON.stringify(userSettings));
                if (userSettings.theme) loadTheme(userSettings.theme);
                if (userSettings.language) i18n.changeLanguage(userSettings.language);
                Api.userSettings = userSettings;
            }
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [userSettings]);

    useEffect(() => {   
        if (!showInitScreen) {
            if (isFlutterApp) {
                console.log('SEND FLUTTER NOTIFICATION: WEB_APP_INITIALIZATION_COMPLETED');
                let payload = '{"command": "WEB_APP_INITIALIZATION_COMPLETED"}';
                sendFlutterNotification(payload);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [showInitScreen]);

    useEffect(() => {
        if(!onlineStatus && !isFlutterApp){
            setShowNetworkError(true);
        } else {
            setShowNetworkError(false);
        }
        
    }, [onlineStatus, isFlutterApp]);


    const handleCloseNetworkErrorCB = () => {
        setShowNetworkError(false);
    }

    const handleHideInitScreen = () => {
        setShowInitScreen(false);
    }
    

    function handleMenuStateChange (state) {
        setMenuIsOpen(state.isOpen);  
    }

    const handleOverlayMenuChange = (state) => {
        setOverlayMode(OVERLAY_MODE.LOGIN);
        setShowOverlay(true);
        setMenuIsOpen(false);  
    }

    const handleOverlayModeChange = (mode) => {
        if (mode === 'LOGIN') {
            setOverlayMode(OVERLAY_MODE.LOGIN);
        }
        else if (mode === 'PROFILE') {
            setOverlayMode(OVERLAY_MODE.PROFILE);
        } 
        else if (mode === 'REGISTER') {
            setOverlayMode(OVERLAY_MODE.REGISTER);
        } 
        else if (mode === 'FORGOTPW') {
            setOverlayMode(OVERLAY_MODE.FORGOTPW);
        }
    }

    const loadConfig = async () => {
      /*  console.log('LOAD CONFIG!!!!');
        try {
            const config = await Api.getConfig();
            setConfig(config);  
        } catch (error) {
            console.log(error);
        }
        */ 
    }

    /*
    const autoLogin = async (storedToken) => {
        try {
            Api.accessToken = storedToken;
            let userMe = await Api.getUserMe();
            let usage = await Api.getMyUsage();
            userMe.usage = usage;
            setUserMe(userMe);
            if (userMe.preferredLanguage) i18n.changeLanguage(userMe.preferredLanguage); 
            
        } catch (error) {
            console.log(error);
            localStorage.removeItem('accessToken');
            Api.accessToken = null;
        }
    }*/

    /* run using startup if no user is logged in and after logout, 
     * then we load data in/from local storage 
     * Unregistered User Data: paymentReceipts, activeSessions
     * NOTE: Currently completedSessions are stored directly in local storage. We should
     *  consider aligning with paymentReceipts and just store ids and fetch sessions from server instead. 
     */
    const loadUnregisteredUserData = async () => {
        //clearUserData();

        // since this method takes forever we need to call it without await. Must fix performance.
        Api.getNearbyLocations(59.328930, 18.064910, 10000000).then( result => {
            setNearbyLocations(HelperService.convertToGeoJSON(result.items));
          }, error => {
            console.log(error);
          }
        )

        await loadActiveUnregisteredSessions();
        let receiptIds = HelperService.getUnregisteredPaymentReceiptIds();
        if (receiptIds && receiptIds.length > 0) {
            let paymentReceipts = null;
            try {
                let result = {};      
                result.items = await Promise.all(receiptIds.map(async id => {
                    return await Api.getUnregisteredPaymentReceiptById(id);
                }));
                paymentReceipts = result;
            } catch (error) {
                console.log('That receipt doesnt exist');
                console.log(error);
            }
            if (paymentReceipts) setPaymentReceipts(HelperService.sortPaymentReceipts(paymentReceipts.items)); 
        }  
    }


    /* Since the usage data, like latest used outlet, isn't updated by user-service until the notification for session ended is sent
       we must call this method from the notification receivers. For some very odd reason userMe is always null in this method so we fetch the user again
       TODO: Understand why userMe is null??????
    */    
    const refreshUsageData = async () => {
        console.log('REFRESH USAGE DATA');
        try {
            let me = await Api.getUserMe();
            if (me) {
                let myUsage = await Api.getMyUsage();
                if (myUsage) me.usage = myUsage;
                setUserMe(me);
                console.log('ALL SET');
            }
        
        } catch (error) {}
    }

    /* run when a user has logged in 
     * Registered User Data: paymentReceipts, activeSessions, completedSessions, paymentCards, nearbyLocations
     */
    const loadRegisteredUserData = async () => {
        //clearUserData();
        try {
            let cards = null; 
            let paymentReceipts = null;
            let activeSessions = []; 
            let completedSessions = [];
            let memberships = null;
            let transactions = null;

            // since this method takes forever we need to call it without await. Must fix performance.
            Api.getNearbyLocations(59.328930, 18.064910, 10000000).then( result => {
                setNearbyLocations(HelperService.convertToGeoJSON(result.items));
              }, error => {
                console.log(error);
              }
            )
            try { cards = await Api.getPaymentCards(userMe.userName); } 
            catch (error) { console.log('could not fetch cards'); }
            if (cards) setPaymentCards(cards.items);
            
            try { transactions = await Api.getTransactions(); } 
            catch (error) { console.log('could not fetch transactions'); }
            if (transactions) setTransactions(transactions);

            try { paymentReceipts = await Api.getPaymentReceipts(userMe.userName); } 
            catch (error) { console.log('could not fetch payment receipts'); }
            if (paymentReceipts) setPaymentReceipts(HelperService.sortPaymentReceipts(paymentReceipts.items)); 
            try { 
                let activeSessionsV2 = await Api.getActiveSessionsV2(userMe.userName); 
                let activeSessionsLegacy = await Api.getActiveSessions(userMe.userName);
                if (activeSessionsV2 && activeSessionsV2.items) activeSessions = activeSessions.concat(activeSessionsV2.items);
                if (activeSessionsLegacy && activeSessionsLegacy.items) activeSessions = activeSessions.concat(activeSessionsLegacy.items);

            } 
            catch (error) { console.log('could not fetch active sessions'); }
            if (activeSessions.length > 0) setActiveSessions(activeSessions);
            try { memberships = await Api.getMemberships(); } 
            catch (error) { console.log('could not fetch memberships'); }
            if (memberships) setMemberships(memberships.items); 

            let from = moment().format();
            try { 
               let completedSessionsLegacy = await Api.getCompletedSessions(userMe.userName, from);
               let completedSessionsV2 = await Api.getCompletedSessionsV2(userMe.userName);
               if (completedSessionsLegacy && completedSessionsLegacy.items) completedSessions = completedSessions.concat(completedSessionsLegacy.items);
                if (completedSessionsV2 && completedSessionsV2.items) {
                    
                    let providerNames = null;
                    
                    try {
                        providerNames = await Api.getProviderNames();
                    } catch (error) {
                        
                    }
                    
                    if(providerNames){
                        completedSessionsV2.items.forEach(async (session) => {
                            if (session.providerRef) {
                                try {
                                    let provider = providerNames.find((provider) => provider.id === session.providerRef);
                                    if (provider) {
                                        session.provider = {};
                                        session.provider.name = provider.name;
                                    }
                                } catch (error) {
                                    
                                }
                                
                            }
                        });
                    }
                    
                   

                    completedSessions = completedSessions.concat(completedSessionsV2.items);
                } 
            } 
            catch (error) { console.log('could not fetch completed sessions'); }
            if (completedSessions) setCompletedSessions(HelperService.sortCompletedSessions(completedSessions));
        } catch (error) {
            console.log(error);
        }
    }    

    // called each time a user changes or logs out
    /*const clearUserData = () => {
        setCompletedSessions(null);
        setPaymentCards(null);
        setActiveSessions(null);
        setPaymentReceipts(null);
    }*/

    // TODO: Just like infrastructure-service has a way to get multiple receipts in one request
    // (with filter=id EQ [xxx] ) we should have the same for sessions!!
    const loadActiveUnregisteredSessions = async () => {
        try {
            let unregActiveSessions = [];
            let sessions = HelperService.getUnregisteredActiveSessions();
                
            for (let i = 0; i < sessions.length; i++) {
                let sessionItem = sessions[i];
                let activeSession = null;
                if (sessionItem.providerId) {
                    activeSession = await Api.getSessionV2(sessionItem.sessionId, sessionItem.providerId);
                }
                else {
                    activeSession = await Api.getSession(sessionItem.sessionId);
                }
                
                unregActiveSessions.push(activeSession);    
            }
            if (unregActiveSessions.length > 0) setActiveSessions(unregActiveSessions);               
        } catch (error) {
            console.log(error);
        }
    }

    const toggleMenu = (open) => {
        setMenuIsOpen(open);
    }


    

    const handleInfoModalOk = () => {
        if (modalOkCallback) modalOkCallback();
        setShowInfoModal(false);
    }

    const handleInfoModalCancel = () => {
        if (modalCancelCallback) modalCancelCallback();
        setShowInfoModal(false);
    }

    const handleSupportModalOk = () => {
        if (supportModalOkCallback) supportModalOkCallback();
        setShowSupportModal(false);
    }
    
    const renderInitScreen = () => {
        return (
            <CSSTransition in={showInitScreen} timeout={400} classNames="init-screen" unmountOnExit >
                <InitScreen/>
            </CSSTransition>
        );
    }

    return (
        <DataStoreProvider value={data}>
            <div className="app-root">
                <div className="side-col" />
                <div className="main-view">
                    <div className="bg"></div>
                    <div className="cover"></div>
                    <div className="containr">
                        <Sidebar isOpen={menuIsOpen} stateChangeCB={handleMenuStateChange} overlayMenuCB={handleOverlayMenuChange}/>
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                children={({ match }) => {
                                    return (
                                        <div className={`transition-group ${pageDirection}`}>
                                            <CSSTransition
                                                in={match != null}
                                                timeout={400}
                                                classNames="page"
                                                unmountOnExit
                                                onEntered={() => {
                                                    document.getElementById("homeView").classList.add('in-background');
                                                }
                                                }
                                                onExited={() => {
                                                    document.getElementById("homeView").classList.remove('in-background');
                                                }
                                                }
                                            >

                                                <div className={`page`}>
                                                    <route.main toggleMenu={toggleMenu}  firstRun={firstRun} handleHideInitScreen={handleHideInitScreen} />
                                                </div>
                                            </CSSTransition>
                                        </div>
                                    );
                                }}
                            />

                        ))}


                        
                        <TopOverlayView mode={overlayMode} showOverlay={showOverlay} overlayModeCB={handleOverlayModeChange} toggleOverlayCB={ ()=>{setShowOverlay(!showOverlay)} }/>

                        {(showInfoModal) ? <InfoModal text={modalText} infoModalOkCB={handleInfoModalOk} infoModalCancelCB={(modalCancelCallback) ? handleInfoModalCancel : null} /> : null}
                        {(showSupportModal) ? <SupportModal text={modalText} provider={supportModalProvider} supportModalOkCB={handleSupportModalOk}  /> : null}
                        {(showHelpTextModal) ? <HelpTextModal lookupKey={modalText} helpTextModalCloseCB={() => setShowHelpTextModal(false)}  /> : null}
                        {(showNetworkError)? <NetworkErrorComponent showNetworkError={showNetworkError} closeCB={handleCloseNetworkErrorCB} text={t('error.no_network')}/>: null}
                        
                    </div>
                    <BottomOverlayView />
                    {renderInitScreen()}
                </div>
                <div className="side-col" />

            </div>

            <div className="app-root menu-cover">
                <div className="side-col" />
                <div className="main-view"></div>
                <div className="side-col" />

            </div>
        </DataStoreProvider>
    );
  };

  export default App; 