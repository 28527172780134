import React, {useState} from 'react';
import ProgressButton from 'js/components/progressButton'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import {useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement} from '@stripe/react-stripe-js';

import { useTranslation } from 'react-i18next';

import 'styles/addCardForm.scss';

export default function AddCardForm({name, submitting, submitCB, cancelCB, errorText}) {
    const stripe = useStripe();
    const elements = useElements();
    const [ cardName, setCardName] = useState(name ? name : '');
    const [ cvcStatus, setCvcStatus] = useState({complete: false, error: ''});
    const [ cardNumberStatus, setCardNumberStatus] = useState({complete: false, error: ''});
    const [ expiryStatus, setExpiryStatus] = useState({complete: false, error: ''});

    const { t } = useTranslation("components");
    const cardStyles = {
        base: {
            fontSize: "19px",
            color: "#424770",
            fontFamily: "sans-serif",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#9e2146"
          }
    };
    
    const cardNumberOptions = {
        iconStyle: "default",
        showIcon: true,
        style: cardStyles
      };
    const options = { style: cardStyles };  

    const handleFormValidation = (event, formName) => {
        let statusObject = {complete: false, error: ''};
        if (!event.empty && event.complete) {
            statusObject.complete = true;
            statusObject.error = '';
        }
        else if (event.error) {
            statusObject.complete = false;
            statusObject.error = event.error.message;
        }
  
        else {
            statusObject.complete = false;
            statusObject.error = '';
        }


        if (formName === 'cvc') {
            setCvcStatus(statusObject);
        }
        else if (formName === 'cardnumber') {
            setCardNumberStatus(statusObject);
        }
        else if (formName === 'expiry') {
            setExpiryStatus(statusObject);
        }

        
    }  

    const handleCancel = (event) => {
        event.preventDefault();
        cancelCB()
        
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        console.log(cardName);

        let card = elements.getElement(CardNumberElement);
        submitCB(card, cardName);

       
    }

    const handleNameChange = (event) => {

        setCardName(event.target.value);
    }
    
    const inputIsIncomlete = () => {
        return ! ( cardNumberStatus.complete  && expiryStatus.complete && cvcStatus.complete && cardName.length > 2  );
    }

    const inputHasError = () => {
        //return false;
        return ! ( cardNumberStatus.error===''  && expiryStatus.error==='' && cvcStatus.error===''  );
    }

    const getErrorText = () => {
        if (errorText) return errorText;
        if  (cvcStatus.error.length > 0 ) return cvcStatus.error; 
        if  (expiryStatus.error.length > 0 ) return expiryStatus.error; 
        if  (cardNumberStatus.error.length > 0 ) return cardNumberStatus.error; 
        return '';
    }

    /* 
    I authorise [your business name] to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.
    */


    return (
        <Modal className="add-card-form" show aria-labelledby="contained-modal-title-vcenter" onHide={()=>{}}>
        
        <div className="content">
            <h1>{t("addCardForm.addCard")}</h1>
        <form onSubmit={handleSubmit}>
            <div className="form-group">
            <label>
            {t("addCardForm.name")}
            <Form.Control className="outlet-input" type="text" value={cardName} placeholder="Anna Andersson" autoComplete="ccname" id="name" name="name" onChange={ handleNameChange}/>    
            </label>

      <label>
      {t("addCardForm.cardNumber")}
        <div className="form-control">
        <CardNumberElement options={cardNumberOptions} onChange={event => { handleFormValidation(event,'cardnumber') }} />
        </div>
      </label>
      <label  className="expiry-date">
      {t("addCardForm.expiryDate")}
        <div className="form-control">
        <CardExpiryElement options={options} onChange={event => { handleFormValidation(event, 'expiry') }} />
        </div>
      </label>
      <label  className="cvc">
      {t("addCardForm.cvc")}
        <div className="form-control">
        <CardCvcElement options={options} onChange={event => { handleFormValidation(event, 'cvc') }} />
        </div>
      </label>
        <div className="error-text">{getErrorText()}</div>

      <ProgressButton disabled={!stripe || inputIsIncomlete() || inputHasError()} className="ok-button" text={t("addCardForm.addCard")} loading={submitting} clickCB={handleSubmit}/>
      <Button className="cancel-button" type="cancel" onClick={handleCancel}>{t("addCardForm.cancel")}</Button>
      </div>
    </form>
        </div>

    </Modal>
  );
}

// 