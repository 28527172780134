import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import helpTexts from 'resources/helpTexts.json';
import 'styles/modals.scss';

/** 
 * A modal dialog with Ok/Cancel buttons for closing. The cancel button is optional, 
 * To use modal import it from DataStoreContext: 
 * const { setInfoModal} = useContext(DataStoreContext);
 *  
 * Then use like this:
 * setInfoModal([ //two button (ok/cancel) modal with customizable actions
            'Are you sure you want to use a modal?', 
            () => () => {console.log('This code runs on OK press');},
            () => () => {console.log('This code runs on Cancel press');}
    ]);

    Or like this: 
    setInfoModal([ //one button (ok) modal with customizable action
            'Are you sure you want to use a modal?', 
            () => () => {console.log('This code runs on OK press');}
    ]);

    Or like this:
    setInfoModal([ //one button (ok) modal which just closes on OK press
            'Are you sure you want to use a modal?', 
    ]);
*/
export function InfoModal({text, infoModalOkCB, infoModalCancelCB}) {
    const { t, i18n } = useTranslation("components");

    const renderText = (text) => {
        // quick hack for eways info text
        const renderPorA = (text, index) => {
            let linktext = 'kontaktformulär';
            if (i18n.language === 'en') linktext = 'contact form';
            if (text && text.startsWith('https://forms.gle')) return (<a href="https://forms.gle/Unp3fJCut5UQCezN7" target="_blank" rel="noopener noreferrer">{linktext}</a>);
            else return (<p key={index}>{text}</p>);
        };

        return (
          <div>
            {Array.isArray(text) ? (
              text.map((item, index) => (
                renderPorA(item, index)
              ))
            ) : (
              <p>{text}</p>
            )}
          </div>
        );
      };
      
    
    return (
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show
        onHide={() => {}}
      >
        <Modal.Body className="info-modal">
          <p>
            {renderText(text)}
          </p>
          <div className="button-row">
            <Button onClick={infoModalOkCB}>OK</Button>
            { (infoModalCancelCB)? <Button className="cancel-btn" onClick={infoModalCancelCB}>{t("modals.cancel")}</Button> : null }
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  export function HelpTextModal({lookupKey, helpTextModalCloseCB}) {
    const { i18n } = useTranslation("components");
    let content = null;
    if (typeof lookupKey === 'string') { // help text is available in the local translation files
        const lookup = lookupKey.split(':') // Lookup key should be entered in the format "category:key" when setting value
    
        if (!helpTexts[lookup[0]] || !helpTexts[lookup[0]][lookup[1]] || !helpTexts[lookup[0]][lookup[1]][i18n.language]) return null;
        content = helpTexts[lookup[0]][lookup[1]][i18n.language];
    }
    else { // help text is provided as an object
        content = lookupKey[i18n.language];
    }

    if (!content) return null;
    
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show
        onHide={() => {}}
      >
        <Modal.Body className="help-text-modal">
            <h1>{content.title}</h1>
            { 
                content.text.map((paragraph, i) => 
                    <p key={i}>{paragraph}</p>
                )
            }
          <div className="button-row">
            <Button onClick={helpTextModalCloseCB}>OK</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  /* Similar to the info modal but this is a modal with an OK button and a Support button next to it. Pressing the support shows support 
   * info in the dialog.
   * Use like this:
   * setSupportModal([ //two button (ok, support) modal with customizable action. Support button show support info from the provider object in the second parameter
            'Are you sure you want to use a modal?',
            {provider}, 
            () => () => {console.log('This code runs on OK press');}
    ]);
   */
  export function SupportModal({text, provider, supportModalOkCB}) {
    
    const { t } = useTranslation("components");
    const [showSupport, setShowSupport] = useState(false);

    const hasSupportContact = () => {
        if (!provider || !provider.supportContact) return false;
        if (provider.supportContact.email || provider.supportContact.phone) return true;
        return false;
    }
    
    const renderSupportContact = () => {
        return (
            <div className="contact-row">
                {(provider.supportContact.phone) ? <span><FontAwesomeIcon icon={faPhone}/>{provider.supportContact.phone}</span> : null }
                {(provider.supportContact.email) ? <span><FontAwesomeIcon icon={faEnvelope}/>{provider.supportContact.email}</span> : null }
            </div>
        );
    }

    const renderText = () => {
      return (
        <div>
          <p>{text[0]}</p>
          {text.length > 1 ? <span className="info">{text[1]}</span>: null}
        </div>
      )
    }

    const renderEmptySupportContact = () => {
        return (
            <div className="contact-row">
                {
                    (provider && provider.name) ? <span>{t('support.contact_details_to_')} {provider.name} {t('support._is_missing_contact_epspot_instead')}</span> 
                    : <span>{t('supportModal.contact_details_missing')}</span> 
                }
            </div>
        );
    }
    
    return (
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show
        onHide={() => {}}
      >
        <Modal.Body className="support-modal">
            {!showSupport ? 
            <>
                {renderText()}
                <div className="button-row">
                    <Button onClick={supportModalOkCB}>OK</Button>
                    { provider ? 
                        <Button className="cancel-btn" onClick={()=>{setShowSupport(true)}}><FontAwesomeIcon icon={faQuestionCircle} /></Button> : null
                    }
                     
                </div>
            </> :
            <div className="support-view">
                <div className="section-1">{t('supportModal.you_have_question_or_something_not_working')}</div>
                <div className="section-2">{t('supportModal.contact_us_for_help')}</div>
                { (provider && provider.name) ? <div className="provider-name">{provider.name}</div> : null } 
                { hasSupportContact() ? renderSupportContact() : renderEmptySupportContact() }
                <div className="button-row">
                    <Button onClick={supportModalOkCB}>OK</Button>
                </div>
            </div>}
          
        </Modal.Body>
      </Modal>
    );
}  


export function LoadingModal({loadText}) {
    return (
        <div className="loading-modal">
            <div className="loading-overlay"/>
            <div className="backplate">
            <div className="load-text">{loadText}</div>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    );
}

export function InitScreen() {
    return (
        <div className="init-screen">
            <div className="bg"/>
            <div className="overlay"/>
            <div className="logo-container">
                <div className="logo"/>
                <div className="pitch">
                    <span>Electricity</span>
                    <span>Access</span>
                    <span>Services</span>
                </div>    
            </div>
            
        </div>
    );
}
